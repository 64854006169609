import React from "react"
import Link from "./link"
import { formatPostDate } from "../utils/date-utils"
import parse from "html-react-parser"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogCard = ({
  id = '',
  title = '',
  date = '',
  uri = '',
  excerpt = '',
  featuredImage = {},
}) => {
  const image = featuredImage && getImage(featuredImage.node.localFile)

  return (
    <div className="blog-box">
      <div className="blog-box_image">
        <GatsbyImage image={image} />
      </div>
      <h2 className="h3 mt-3">{title}</h2>
      <p>{formatPostDate(date)}</p>
      {parse(excerpt)}
      <Link to={uri} className="stretched-link"></Link>
    </div>
  )
}

export default BlogCard