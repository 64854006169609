import React from "react"
import { graphql, navigate } from "gatsby"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import CollectionGrid from "../../components/collection-grid"
import Title from "../../components/title"

export const query =  graphql`
  query TEMPLATE_WP_POST_COLLECTION_QUERY(
    $ids: [String]
  ) {
    allWpPost(
      limit: 12
      filter: {
        id: {
          in: $ids
        }
      }
      sort: {
        fields: dateGmt
        order: DESC
      }
    ) {
      nodes {
        ...postData
        ...postFeaturedImage
      }
    }
  }
`

const wpCatgegoryCollectionTemplate = ({ 
    data = {},
    location: {
      pathname = ''
    },
    pageContext: {
      pageNumber = 0,
      hasNextPage = false,
      hasPreviousPage = false
    }
 }) => {
  const { allWpPost: { nodes: catPosts = [] } } = data
  const blogPageNumber = pageNumber ? `Page ${pageNumber + 1}` : ''
 
  return (
    <React.Fragment>
      <Seo title="Theme Park Blog & Latest News | Blackgang Chine" />
      <main>
        <Title title="Theme Park Blog & Latest News | Blackgang Chine" />

        <div className="container py-5">
          <p className="mb-5 text-center">
            Check out the Blackgang Chine blog for the latest park news and events, as well as tips for planning the best family day.
          </p>

          <CollectionGrid catPosts={catPosts}/>

          <div className="d-flex justify-content-between">
            {hasPreviousPage &&
              <button
                className="btn btn-primary"
                onClick={() => 
                  navigate(
                    pageNumber > 1
                      ? `/blog/${pageNumber}`
                      : '/blog'
                  )
              }>Previous</button>
            }

            {hasNextPage &&
              <button
                className="btn btn-primary"
                onClick={() => 
                  navigate(`/blog/${pageNumber + 2}`)
              }>Next</button>
            }
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}

export default wpCatgegoryCollectionTemplate