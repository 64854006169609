import React from "react"
import BlogCard from "./blog-card"

const CollectionGrid = ({ catPosts = [] }) => {
  return (
    <div className="row">
      {catPosts.length > 0 && catPosts.map((node) => {
        const {
          id = '',
          title = '',
          date = '',
          excerpt = '',
          uri = '',
          featuredImage = {}
        } = node

        return (
          <div key={id} className="col-12 col-md-4 mb-3">
            <BlogCard
              id={id}
              title={title}
              date={date}
              uri={uri}
              featuredImage={featuredImage}
            />
          </div>
        )
      })}
    </div>
  )
}

export default CollectionGrid